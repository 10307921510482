<template>
  <el-row>
    <span>浏览记录</span>
  </el-row>
</template>

<script>
export default {
  name: 'Visit',
  data() {
    return {
    }
  },
  created() {
    document.title = '我的播放列表'
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
